import { Helmet } from "react-helmet";

export default function Reports(){
    return(
        <div>
            <Helmet>
                <meta property="og:description" content="Project reports for the Lubbock 2022 Street Bond Program." />
                <meta property="og:title" content="Reports | City of Lubbock Street Bond" />
                <meta property="og:url" content="https://www.lubbock2022streetbond.com/reports"/>
            </Helmet>
            <div className="flex-shrink-0 modular min-vh-100">
                <img src={`/assets/downtown${Math.floor(Math.random()*3)}.png`} className="d-block w-100" style={{ objectFit: "cover", height: "auto" }} alt="Reports"/>
                <div className="container">
                    <div className='row text-start my-4'>
                        <h1 className="text-center text-secondary py-5 my-5"><em>Coming Soon</em></h1>
                    </div>
                    <div className='row text-start'>
                    </div>
                </div>
            </div>
        </div>
    )
}