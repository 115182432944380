import { Helmet } from "react-helmet";
import pic1 from '../assets/photos/pic1.jpg'
import pic2 from '../assets/photos/pic2.jpg'
import pic3 from '../assets/photos/pic3.jpg'

import pic4 from '../assets/constructionUpdates/DJI_0952.JPG'
import pic5 from '../assets/constructionUpdates/DJI_0956.JPG'
import pic6 from '../assets/constructionUpdates/DJI_0961.JPG'

export default function Events() {
    const upcomingEventsList = [];
    const pastEventsList = ["98th Street (Alcove Avenue to Upland Avenue) Groundbreaking"];

    return (
        <div>
            <Helmet>
                <meta property="og:description" content="Events reguarding projects the Lubbock 2022 Street Bond Program." />
                <meta property="og:title" content="Events | City of Lubbock Street Bond" />
                <meta property="og:url" content="https://www.lubbock2022streetbond.com/events" />
            </Helmet>
            <div className="flex-shrink-0 modular min-vh-100">
                <img src={`/assets/downtown${Math.floor(Math.random() * 3)}.png`} className="d-block w-100" style={{ objectFit: "cover", height: "300px" }} alt="Events" />
                <div className="container">
                    <div className='row text-start mt-4'>
                        <h1 className='mb-4' style={{ color: "#00354C" }}>Upcoming Events</h1>
                        {(upcomingEventsList.length === 0) ? <h3 style={{ height: "250px" }} className="text-center text-secondary"><em>No Upcoming Events</em></h3> : upcomingEventsList}
                    </div>
                    <div className='row text-start mt-4'>
                        <h1 style={{ color: "#00354C" }}>Past Events</h1>
                        {(pastEventsList.length === 0) ? <h3 style={{ height: "250px" }} className="text-center text-secondary"><em>No Past Events</em></h3> : <h4>{pastEventsList}</h4>}
                        
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-4">
                                <img src={pic1} className="p-2 img-fluid"/>
                                </div>
                                <div className="col-4">
                                <img src={pic2} className="p-2 img-fluid"/>
                                </div>
                                <div className="col-4">
                                <img src={pic3} className="p-2 img-fluid"/>
                                </div>
                            </div>
                        </div>
                        <h4 className="mt-3">146th Street (Quaker Avenue to Indiana Avenue) Groundbreaking 7/11/24 at 10 am.</h4>
                        <div className="card mt-3">
                            <div className="card-body row">
                                <div className="col-4">
                                <img src={pic4} className="p-2 img-fluid"/>
                                </div>
                                <div className="col-4">
                                <img src={pic5} className="p-2 img-fluid"/>
                                </div>
                                <div className="col-4">
                                <img src={pic6} className="p-2 img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}