import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './styles/App.css';

import Home from './pages/Home'
import Projects from './pages/Projects';
import Events from './pages/Events';
import Page404 from './pages/404';
import Reports from './pages/Reports'
import Project from './pages/Project'
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo({ top:0, left:0, behavior: "instant"});
    }, [pathname]);
    return null;
  }

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <ScrollToTop/>
                <Navbar/>
                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/projects' element={<Projects/>}/>
                    <Route path='/events' element={<Events/>}/>
                    <Route path='/reports' element={<Reports/>}/>
                    <Route path='/project/:id' element={<Project/>}/>
                    <Route path='*' element={<Page404/>}/>
                </Routes>
            </BrowserRouter>
            <Footer/>
        </div>
    );
}

export default App;
