import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Home() {
    return (
        <div>
            <Helmet>
                <meta property="og:description" content="The City of Lubbock 2022 Street Bond Program" />
                <meta property="og:title" content="Home | City of Lubbock Street Bond" />
                <meta property="og:url" content="https://www.lubbock2022streetbond.com/" />
            </Helmet>
            <div className="flex-shrink-0 modular min-vh-100">
                <img src='/assets/downtown0.png' className="d-block w-100" style={{ objectFit: "cover", height: "300px" }} alt="Home" />

                <div className='container'>
                    <div className='row text-start'>
                        <div className='col-lg-8'>
                            <div className="card shadow mt-4">
                                <div className="card-header h1 fw-bold" style={{ color: "#00354C" }}>
                                     Progress of City of Lubbock Street Bond
                                </div>
                                <div className="card-body">
                                    <p>
                                        The City of Lubbock continues to experience significant population growth, placing demands on the existing transportation system. On November 8, 2022, The City of Lubbock voters passed Proposition A with a 68% approval rate. To increase mobility, reduce congestion, enhance safety, and improve connectivity, the City of Lubbock is actively implementing projects identified in The 2022 Street bond program.
                                        <br /><br />
                                        As we drive forward to meet the critical transportation infrastructure needs across the city, we invite you to explore this website and learn more about the projects funded by the City of Lubbock 2022 Street Bond. Click on the map and icons below to learn more.
                                        <br /><br />
                                        City of Lubbock Master Thoroughfare Plan: <Link target="_blank" to="https://ci.lubbock.tx.us/pages/public-projects/planlubbock-2040">https://ci.lubbock.tx.us/pages/public-projects/planlubbock-2040</Link>
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-4'>
                            <div className='card border shadow mt-4'>
                                <div className='card-body text-center'>
                                    <Link to="/projects">
                                        <img className='img-fluid' src='/assets/map.png' alt="Map" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}