import { Link, useLocation } from "react-router-dom";
import '../styles/Navbar.css'
import seal from '../assets/city-of-lubbock-logo.png'
import { useEffect, useState } from "react";
import projects from '../assets/projects.json'
import NavbarLine from "./NavbarLine";

export default function Navbar() {
    const { pathname } = useLocation();

    function isActive(p) {
        return p === pathname ? ' active' : ''
    }

    const [projectList, setProjectList] = useState([]);

    const initData = () => {
        setProjectList(projects.map((li) => {
            return <NavbarLine key={li.id} roadway={li.roadway} limitsFrom={li.limitsFrom} limitsTo={li.limitsTo} id={li.id} />
        }))
    }

    useEffect(() => {
        initData()
    }, [])

    return (
        <div>
            <nav className="navbar navbar-expand-lg" style={{ backgroundColor: '#F2F2F2' }}>
                <div className="container">
                    <Link className="navbar-brand" to='https://ci.lubbock.tx.us/'><img src={seal} style={{ height: '70px' }} alt='City of Lubbock' /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-dark py-0" style={{ backgroundColor: '#00354C' }}>
                <div className="container">
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav fw-bold ps-5" style={{ fontSize: '20px' }}>
                            <Link className={`nav-link hover pe-5 mx-1${isActive('/')}`} to="/">
                                <i className='bi me-2 bi-house-door' />
                                Home
                            </Link>
                            <Link className={`nav-link hover pe-5 mx-1${isActive('/events')}`} id='events' to="/events">
                                <i className="bi bi-calendar-check me-2" />
                                Events
                            </Link>
                            <Link className={`nav-link hover pe-5 mx-1${isActive('/projects')}`} id='projects' to="/projects">
                                <i className="bi bi-map me-2" />
                                Project Map
                            </Link>
                            <li className="nav-item hover dropdown pe-5 mx-1" >
                                <div className="nav-link hover dropdown-toggle bold" role="button" data-bs-toggle="dropdown">
                                    <i className="bi bi-folder me-2" />
                                    Projects
                                </div>
                                <ul className="dropdown-menu fw-bold" style={{ overflowY: "scroll", height: "600px" }}>
                                    {projectList}
                                </ul>
                            </li>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}