import { createRef, useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import projects from "../assets/projects.json"
import Spinner from "../components/Spinner"
import { Galleria } from 'primereact/galleria';

export default function Project() {
    const { id } = useParams()
    const [project, setProject] = useState()
    const [associatedProjects, setAssociatedProjects] = useState([]);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [images, setImages] = useState([])
    const [imageButtons, setImageButtons] = useState([])
    const [imagesConst, setImagesConst] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const galleria = useRef(null);
    const navigate = useNavigate()
    const ref = createRef()

    useEffect(() => {
        var data = null
        for (let i = 0; i < projects.length; i++) if (id === projects[i].id) data = projects[i]
        if (data == null) setError(true)
        console.log(data);
        setProject(data)
        var _images = []
        var _imageButtons = []
        var _imagesConst = []

        if (data != null) {
            for (let i = 0; i < data.map.length; i++) {
                if (i === 0) {
                    _images.push(<div className="carousel-item active" key={i}><img className='d-block w-100 img-fluid' src={data.map[i]} alt="&nbsp;&nbsp;Unavailable" /></div>)
                    if (data.map.length > 1) _imageButtons.push(<button type="button" data-bs-target="#projectCarousel" data-bs-slide-to={i} className="active" aria-current="true" aria-label={`Slide ${i + 1}`} key={i} />)
                }
                else {
                    _images.push(<div className="carousel-item" key={i}><img className='d-block w-100 img-fluid' src={data.map[i]} alt="&nbsp;&nbsp;Unavailable" /></div>)
                    _imageButtons.push(<button type="button" data-bs-target="#projectCarousel" data-bs-slide-to={i} aria-label={`Slide ${i + 1}`} key={i} />)
                }
            }

            for (let i = 0; i < data.constructionPhotos.length; i++) {
                if (i === 0) {
                    _imagesConst.push(data.constructionPhotos[i])
                }
                else {
                    _imagesConst.push(data.constructionPhotos[i])
                }
            }
            console.log(_imagesConst)
        }

        if(data.associatedProjects){
            setAssociatedProjects(data.associatedProjects.map((a)=>{
                return <li>{a}</li>
            }));
        }

        setImagesConst(_imagesConst)
        setImages(_images)
        setImageButtons(_imageButtons)
        setLoading(false)
    }, [id])

    useEffect(() => {
        const project = document.getElementById('project')
        var _imagesConst = []

        if (project == null) return;
        else {
            window.scrollTo({ left: 0, top: project.getBoundingClientRect().top - 100, behavior: 'smooth' })
        }
    }, [ref])


    const itemTemplate = (item) => {
        return <img src={item} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={item} alt={item.alt} className="img-thumbnail" style={{ display: 'block' }} />;
    }


    if (loading) return (<main className='flex-shrink-0 modular min-vh-100'><Spinner padding={5} /></main>)
    if (error) return <main className='flex-shrink-0 modular min-vh-100'><div className="py-5">Project Not Found</div></main>

    return (
        <div className="flex-shrink-0 modular min-vh-100">
            <div id="myCarousel" className="carousel slide pointer-event" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item-s active c0 c1">
                        <img src={`/assets/downtown${Math.floor(Math.random() * 3)}.png`} className="d-block w-100" style={{ objectFit: "cover", height: "300px", opacity: "50%", }} alt="Project" />
                    </div>
                </div>
            </div>
            <div className="container" ref={ref}>

                <div id='project' className='row text-start my-4'>
                    <div className='col-lg-6'>

                        <div className="card border shadow">
                            <div className="card-header h1">
                                {project.roadway}
                            </div>
                            <div className="card-body">
                                {(project.limitsFrom.length !== 0) && <h6><b>Project Limits: </b>From {project.limitsFrom} {(project.limitsTo.length !== 0) && `to ${project.limitsTo}`}</h6>}

                                {(project.location.length !== 0) && <h6><b>Location:  </b>{project.location}</h6>}

                                <h6><b>Project Status: </b>{(project.projectStatus === "") ? <i>Status Unavailable</i> : project.projectStatus} </h6>
                                <h6>{project.id == 5 || project.id == 4 || project.id == 8 || project.id == 1 ? <b>Anticipated Construction Completion Date: </b> : <b>Anticipated Construction Start Date: </b>} {(project.estimatedCompletion === "") ? <i>Date Unavailable</i> : project.estimatedCompletion}</h6>
                                {project.contractor.length !== 0 ? <h6><b>Contractor:</b> {project.contractor} </h6> : <></>}
                                {project.constructionContractAmount.length !== 0 ? <h6><b>Construction Contract Amount: </b>{project.constructionContractAmount} </h6> : <></>}
                                {project.id == 4 ? "*Also includes construction for 34th Street (Quaker Avenue to Slide Road)" : ""}
                                {project.id == 5 ? "*Also includes construction for 34th Street (Avenue Q to IH-27)" : ""}

                                {(project.projectDescription === "") ? <p className="my-3"><i>Description Unavailable</i></p> : <p className='mt-4' style={{ whiteSpace: 'pre-wrap' }}><b>Description: </b>{project.projectDescription}</p>}
                                <div>
                                    <ul>
                                        {associatedProjects}
                                    </ul>
                                </div>
                                <h5 className="fw-bold">Project Contact</h5>
                                <ul style={{ listStyleType: "none" }}>
                                    <li>{project.projectContact.name}</li>
                                    <li>{project.projectContact.number}</li>
                                    <a href={`mailto:${project.projectContact.email}?subject=Lubbock 2022 Street Bond: ${project.roadway}`}>{project.projectContact.email}</a>
                                    <li>Project Reference Number: {project.projectRefNumber}</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-6'>
                        <div className='card shadow border'>
                            <div className='card-body'>
                                <div id="projectCarousel" className="carousel slide" data-bs-ride="true" >
                                    <div className="carousel-indicators">
                                        {imageButtons}
                                    </div>
                                    <div className="carousel-inner">
                                        {images}
                                    </div>
                                    {(project.map.length > 1) && (<><button className="carousel-control-prev" type="button" data-bs-target="#projectCarousel" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#projectCarousel" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button></>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {imagesConst.length != 0?
                    <div className="row text-start my-4">
                        <div className="col-lg-12 card shadow px-0">
                            <div className="card-header h3">
                                Construction Updates
                            </div>
                            <div className='card-body justify-content-center'>
                                <Galleria ref={galleria} value={imagesConst} numVisible={7} style={{ maxWidth: '80%' }}
                                    activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                                    circular fullScreen={true} showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />

                                <div className="grid row">
                                    {
                                        imagesConst && imagesConst.map((image, index) => {
                                            let imgEl = <img class="m-3" src={image} alt={image} style={{ cursor: 'pointer', width: "100%", borderRadius: "2%" }} onClick={
                                                () => { setActiveIndex(index); galleria.current.show() }
                                            } />
                                            return (
                                                <div className="col-3" style={{ width: "33%" }} key={index}>
                                                    {imgEl}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
                }

                <button className='btn btn-outline-secondary btn-sm me-5' onClick={() => { navigate('/', { state: { fromProjects: true } }) }}><i className='bi me-2 bi-house-door' />Back to Home</button>
                <button className='btn btn-outline-secondary btn-sm' onClick={() => { navigate('/Projects', { state: { fromProjects: true } }) }}><i className="bi bi-map me-2" />Back to Project Map</button>
            </div>
        </div>
    )
}