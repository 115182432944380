import React from 'react'
import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom';

export default function NavbarLine(props) {
    const location = useLocation();

    const { pathname } = location;

    const splitLocation = pathname.split("/");

    const roadway = props.roadway
    const projectId = props.id
    const limitsFrom = props.limitsFrom
    const limitsTo = props.limitsTo

    return (
        <li className="dropdown-item">
            <Link className="nav-link text-dark" id={(splitLocation[2] == { projectId } ? "activeItem" : "")} to={"/project/" + projectId} >{roadway} {(limitsFrom.length !== 0) && <>({limitsFrom} {(limitsTo.length !== 0) && `to ${limitsTo}`})</>}</Link>
        </li>
    )
}